// src/App.js
import React from "react";
import ChatApp from "./components/ChatApp";
import "./App.css";

function App() {
  return (
    <div className="App">
      <ChatApp />
    </div>
  );
}

export default App;

// src/App.js
// import React from "react";
// import ChatGPTQuery from "./components/ChatGPTQuery";
// import "./App.css";

// function App() {
//   return (
//     <div className="App">
//       <ChatGPTQuery />
//     </div>
//   );
// }

// export default App;
