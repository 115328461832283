// src/MessageList.js
import React from "react";
import "./MessageList.css";

const MessageList = ({ messages }) => {
  return (
    <div className="message-list">
      {messages.map((msg, index) => (
        <div key={index} className="message">
          <p className="Before"> {msg[0]}</p>
          <p> {msg[1]}</p>
        </div>
      ))}
    </div>
  );
};

export default MessageList;
