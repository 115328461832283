// src/MessageInput.js
import React, { useState } from "react";
import "./MessageInput.css";

const MessageInput = ({ addMessage }) => {
  const [input, setInput] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const apiKey = "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";

      const messages = [
        {
          role: "user",
          content: `Grammar check: "${input} ".`,
        },
      ];

      await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: "gpt-4o-mini",
          max_tokens: 150,

          messages: messages,
        }),
      }).then((r) => {
        r.json().then((r) => {
          //alert(r.usage.total_tokens);
          addMessage([input, r.choices[0].message.content]);
          setInput("");
        });
      });

      // setResponse(result.data.choices[0].text.trim());
    } catch (error) {
      addMessage(["An error occurred while fetching data.", ""]);
    } finally {
      // setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="message-input">
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type a message..."
      />
      <button type="submit">Send</button>
    </form>
  );
};

export default MessageInput;
